import React from 'react'
import './ParticipateSection.scss'

const ParticipateSection = props => (
  <section id="participate-section">
    <div className="container participate-section">
      <div className="gridd-title">
        <h2 className="gridd-title-h2">How do you want to participate?</h2>
      </div>
      {/* <div className="gridd">
      <button>
        <a href="/sponsor/">Sign Up for Updates</a>
      </button>
      <button>
        <a href="/about/">Sign Up for Updates</a>
      </button>
      <button>
        <a href="/contact/">Sign Up for Updates</a>
      </button>
      </div> */}
      <div className="Grid Grid--gutters Grid--cols-3 Grid--center">
        <a className="Grid-cell" href="https://forms.gle/p5NxfsGr9neCuryRA">
          Volunteer
        </a>
        <a className="Grid-cell" href="https://forms.gle/BohXorXfL7aHLWCX6">
          Vendor/Table
        </a>
        <a className="Grid-cell" href="https://forms.gle/KBCKsvRUsALNrJve7">
          Performer/Artist
        </a>
      </div>
    </div>{' '}
  </section>
)

export default ParticipateSection
