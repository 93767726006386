import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  // NavLink,
} from 'reactstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGithub } from '@fortawesome/free-brands-svg-icons'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import Aux from '../../hoc/Auxiliary'
// import Img from '../image'
import './Navigation.scss'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Aux>
        <Navbar light expand="md">
          <div className="container flexnav">
            <NavbarBrand href="/">
              {/* Your Brand <span role="img" aria-label="rocket"> 🚀</span> */}
              <img
                src="http://d3n8a8pro7vhmx.cloudfront.net/themes/590377436ad57556ea000000/attachments/original/1555792644/afrofestlogo.png?1555792644"
                alt=""
                width="100%"
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/sponsor">
                    Sponsors/Partners
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <NavLink href="https://github.com/shal0mdave">
                    {' '}
                    <FontAwesomeIcon icon={faGithub} /> GitHub
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav-link sponsornav" to="/sponsor">
                    Become a Sponsor
                  </Link>
                </NavItem> */}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </Aux>
    )
  }
}

export default Navigation
