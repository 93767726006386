import React from 'react'
import './Footer.scss'

const Footer = () => (
  <footer>
    <div className="topfooter">
      <div className="container topfooterdiv">
        <div className="center">
          <div className="footerlogo" />
        </div>
      </div>
    </div>
    <div className="bottomfooter">
      <div className="container">
        <div className="footer-inner">
          <p>Copyright @ 2019 AfroFest Minnesota. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
