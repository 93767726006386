import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import './EmailSuscribe.scss'

export default class EmailSuscribe extends React.Component {
  state = {
    name: null,
    email: null,
  }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    })
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    console.log('submit', this.state)

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
  }

  render() {
    return (
      <section id="email-subscribe" className="container widesuscribe">
        <h2 className="suscribe-title">Sign up for updates</h2>
        <div>
          <form className="email-form" onSubmit={this._handleSubmit}>
            <input
              className="email-form-input name-input"
              type="text"
              onChange={this._handleChange}
              placeholder="Name"
              name="name"
            />
            <input
              className="email-form-input email-input"
              type="email"
              onChange={this._handleChange}
              placeholder="Email"
              name="email"
            />
            <input className="email-form-submit" type="submit" />
          </form>
        </div>
      </section>
    )
  }
}
